import React, { useEffect, useState } from 'react';
import '../../Styles/card.css';
import { useLocation } from 'react-router-dom';
import { StatusAPI, TotalRegionAPI } from '../../Services/APIManager';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Cards() {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [totalRegions, setTotalRegions] = useState(0);
  const [dashboardStats, setDashboardStats] = useState({
    totalRiders: 0,
    totalMarkers: 0,
    totalMarkersMissing: 0,
    totalRiderUsers: 0,
    currentLiveTrips: 0
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    async function fetchRegionCount() {
      try {
        let res = await TotalRegionAPI();
        console.log("TotalRegionAPI Response:", res); 
        if (res?.data?.totalRegions !== undefined) {
          setTotalRegions(res.data.totalRegions);
        } else {
          console.log("No total regions data found");
        }
      } catch (e) {
        console.log("Error in getting total region count", e);
      }
    }
    fetchRegionCount();
  }, []);

  useEffect(() => {
    async function fetchDashboardStats() {
      try {
        let res = await StatusAPI();
        console.log("StatusAPI Response:", res); 
        if (res?.data !== undefined) {
          setDashboardStats(res.data);
        } else {
          console.log("No dashboard stats data found");
        }
      } catch (e) {
        console.log("Error in getting dashboard stats", e);
      }
    }
    fetchDashboardStats();
  }, []);

  return (
    <div className='cards'>
      <Helmet>
        <title>Dashboard - GAIL Marker Surveillance System</title>
        <meta name="description" content="Overview of the GAIL Marker Surveillance System including total riders, markers, regions, and live trips." />
        <meta name="keywords" content="GAIL, Marker Surveillance, Dashboard, Trips, Regions" />
      </Helmet>
      <h3>Dashboard</h3>
      <div className='card-container'>
        <div className='card' onClick={() => navigate("/getusers")}>
          <h4>Total Riders</h4>
          <p>{dashboardStats.totalRiders}</p>
        </div>
        <div className='card' onClick={() => navigate("/markers-map")}>
          <h4>Total Markers</h4>
          <p>{dashboardStats.totalMarkers}</p>
        </div>
        <div className='card' onClick={() => navigate("/regions")}>
          <h4>Total Regions</h4>
          <p>{totalRegions}</p>
        </div>
        <div className='card'>
          <h4>Total Markers Missing</h4>
          <p>{dashboardStats.totalMarkersMissing}</p>
        </div>
        <div className='card' onClick={() => navigate("/trip-page")}>
          <h4>Total Trips</h4>
          <p>{dashboardStats.totalTrips}</p>
        </div>
        <div className='card'>
          <h4>Current Live Trips</h4>
          <p>{dashboardStats.currentLiveTrips}</p>
        </div>
      </div>
    </div>
  );
}


// import React,{useEffect} from 'react';
// import '../../Styles/card.css';
// import { useLocation } from 'react-router-dom';
// import { TotalRegionAPI } from '../../Services/APIManager';

// export default function Cards() {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);
//   async function GetRegioncount() {
//     try {

//       let res = await TotalRegionAPI();
//       if(res?.data.length){
//       console.log("count of the region", res)
//       }
//     } catch (e) {
//       console.log("error in getting officials", e);
//       return null;
//     }
//   }

//   return (
//     <div className='cards'>
//       <h3>Dashboard</h3>
//       <div className='card-container'>
//         <div className='card'>
//           <h4>Total Trips</h4>
//           <p>100</p>
//         </div>
//         <div className='card'>
//           <h4>Total Markers</h4>
//           <p>250</p>
//         </div>
//         <div className='card'>
//           <h4>Total Markers Missing</h4>
//           <p>5</p>
//         </div>
//         <div className='card'>
//           <h4>Total Rider Users</h4>
//           <p>120</p>
//         </div>
//         <div className='card'>
//           <h4>Current Live Trips</h4>
//           <p>10</p>
//         </div>
//         <div className='card'>
//           <h4>User Requests</h4>
//           <p>8</p>
//         </div>
//         <div className='card'>
//           <h4>Recent Photo Uploads</h4>
//           <p>15</p>
//         </div>
//         <div className='card'>
//           <h4>Total Regions</h4>
//           <p></p>
//         </div>
//       </div>
//     </div>
//   );
// }

