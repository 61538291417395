const config = {
    BASE_URL:" https://gailapi.binarysystems.in/api/v1/",
    GAIL_URL:"https://gailapi.binarysystems.in/api/v1/"
    // BASE_URL:" http://13.127.221.252:3057/api/v1/",
    // GAIL_URL:"http://13.127.221.252:3057/api/v1/"
    
}

export default config

