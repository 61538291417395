import React from 'react'
import '../../Styles/home.css'
const Home = () => {
  return (
    <div className='home'>
      <h1>Welcome  to <br/>GAIL Marker Surveillance System</h1>
    </div>
  )
}

export default Home
