import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FaSearch, FaSync } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TotalRegionAPI, fetchMarkersByRegion, fetchMarkersById } from '../../Services/APIManager';
import PinImagesModal from '../MarkersMap/PinImagesPage';
import '../../Styles/mappage.css';
import Redmap from '../../Assets/Redmap.png';
import { Helmet } from 'react-helmet';
const defaultIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

const highlightedIcon = new L.Icon({
  iconUrl: Redmap,
  iconRetinaUrl: Redmap,
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

export default function MapPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [highlightedMarkers, setHighlightedMarkers] = useState([]);
  const [markersData, setMarkersData] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [regions, setRegions] = useState([]);
  const [regionInfo, setRegionInfo] = useState(null);
  const [mapCenter, setMapCenter] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const markerRefs = useRef({});

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const getRegions = async () => {
      try {
        const data = await TotalRegionAPI();
        if (data && data.data && data.data.regions) {
          setRegions(data.data.regions);
        } else {
          console.error('Failed to fetch regions');
        }
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };
    getRegions();
  }, []);

  const fetchData = async (regionId, pageNumber = 1) => {
    try {
      const data = await fetchMarkersByRegion(regionId, pageNumber);
      if (data?.data?.markers) {
        const sortedMarkers = data.data.markers.sort((a, b) => a.markerId - b.markerId);
        if (pageNumber === 1) {
          setMarkersData(sortedMarkers);
          if (sortedMarkers.length > 0) {
            setMapCenter([parseFloat(sortedMarkers[0].latitude), parseFloat(sortedMarkers[0].longitude)]);
          }
        } else {
          setMarkersData((prevMarkers) => [...prevMarkers, ...sortedMarkers]);
        }
        setSearchPerformed(true);
        setHasMore(data.data.hasMore);
        const selectedRegion = regions.find((region) => region.regionId.toString() === regionId);
        setRegionInfo(selectedRegion || null);
      } else {
        console.error('API returned an unexpected response:', data);
      }
    } catch (error) {
      console.error('Error fetching markers:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      fetchData(searchQuery);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleMarkerClick = async (markerId, latitude, longitude) => {
    try {
      const data = await fetchMarkersById(markerId);
      if (data && data.data) {
        setSelectedMarker(data.data);
        setMapCenter([parseFloat(latitude), parseFloat(longitude)]);
        if (markerRefs.current[markerId]) {
          markerRefs.current[markerId].openPopup();
        }
      }
    } catch (error) {
      console.error('Error fetching marker details:', error);
    }
  };

  const handleCoordinateClick = (markerId, latitude, longitude) => {
    setHighlightedMarkers([markerId]);
    setMapCenter([parseFloat(latitude), parseFloat(longitude)]);
    if (markerRefs.current[markerId]) {
      markerRefs.current[markerId].openPopup();
    }
  };

  const loadMoreMarkers = () => {
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      fetchData(searchQuery, newPage);
      return newPage;
    });
  };

  const isMobile = window.innerWidth < 900;
  const sortedMarkers = isMobile ? [...markersData].reverse() : markersData;

  return (
    <div className='map-page'>
       <Helmet>
        <title>Markers Map - GAIL Marker Surveillance System</title>
        <meta name="description" content="View and interact with markers on the map by region ID." />
        <meta name="keywords" content="map, markers, region, search, react, leaflet" />
      </Helmet>
      <h3>Markers</h3>
      <form onSubmit={handleSearchSubmit}>
        <div className='search-container'>
          <input
            type='text'
            placeholder='Enter region ID to search'
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button type='submit'>
            <FaSearch />
          </button>
          <button type='button' onClick={handleRefresh}>
            <FaSync />
          </button>
        </div>
      </form>

      {regionInfo && (
        <div className='about-region'>
          <p>
            <span>Region ID:</span> {regionInfo.regionId}
          </p>
          <p>
            <span>Region Name:</span> {regionInfo.regionName}
          </p>
          <p>
            <span>Total Markers:</span> {regionInfo.totalMarkers}
          </p>
          <p>
            <span>Missing Markers: </span>
            {regionInfo.missingMarkers}
          </p>
          <p>
            <span>Last Trip:</span> {regionInfo.lastTrip}
          </p>
        </div>
      )}

      {searchPerformed && mapCenter && (
        <div className='content-container'>
          <div className='map-container'>
            <MapContainer center={mapCenter} zoom={15} style={{ height: '60vh', width: '100%' }}>
              <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
              {sortedMarkers.map((marker) => (
                <Marker
                  key={marker.markerId}
                  position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                  icon={highlightedMarkers.includes(marker.markerId) ? highlightedIcon : defaultIcon}
                  ref={(ref) => {
                    if (ref) {
                      markerRefs.current[marker.markerId] = ref;
                    }
                  }}
                  eventHandlers={{
                    click: () => handleMarkerClick(marker.markerId, marker.latitude, marker.longitude),
                  }}
                >
                  <Popup>
                    <div>
                      <strong>Marker ID:</strong> {marker.markerId}<br />
                      <strong>Coordinates:</strong> {marker.latitude}, {marker.longitude}
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
          <div className='marker-list-container'>
            <div className='marker-list-header'>
              <h4>Markers</h4>
              <h4>Coordinates</h4>
            </div>
            <InfiniteScroll
              dataLength={sortedMarkers.length}
              next={loadMoreMarkers}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget='marker-list-container'
            >
              <div className='marker-list' id='marker-list-container'>
                {sortedMarkers.map((marker) => (
                  <div
                    key={marker.markerId}
                    className='marker-row'
                    onClick={() => handleCoordinateClick(marker.markerId, marker.latitude, marker.longitude)}
                    style={{ color: highlightedMarkers.includes(marker.markerId) ? 'red' : 'black' }}
                  >
                    <div>Pin {marker.markerId}</div>
                    <div>{`${marker.latitude}, ${marker.longitude}`}</div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      )}

      {selectedMarker && (
        <PinImagesModal marker={selectedMarker} handleClose={() => setSelectedMarker(null)} />
      )}
    </div>
  );
}


// import React, { useState, useEffect } from 'react';
// import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import { FaSearch, FaSync } from 'react-icons/fa';
// import { useLocation } from 'react-router-dom';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { TotalRegionAPI, fetchMarkersByRegion, fetchMarkersById } from '../../Services/APIManager';
// import PinImagesModal from '../MarkersMap/PinImagesPage';
// import '../../Styles/mappage.css';
// import Redmap from '../../Assets/Redmap.png';
// import UserIconImage from '../../Assets/UserIcon.png'; // Add a user icon image here

// const defaultIcon = new L.Icon({
//   iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
//   iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
//   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   tooltipAnchor: [16, -28],
//   shadowSize: [41, 41],
// });

// const highlightedIcon = new L.Icon({
//   iconUrl: Redmap,
//   iconRetinaUrl: Redmap,
//   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   tooltipAnchor: [16, -28],
//   shadowSize: [41, 41],
// });

// const userIcon = new L.Icon({
//   iconUrl: UserIconImage,
//   iconRetinaUrl: UserIconImage,
//   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
//   iconSize: [40, 40],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   tooltipAnchor: [16, -28],
//   shadowSize: [41, 41],
// });

// const jaipurCoordinates = [26.9124, 75.7873];

// function UserLocationMarker({ userLocation }) {
//   const map = useMap();

//   useEffect(() => {
//     if (userLocation) {
//       const marker = L.marker(userLocation, { icon: userIcon }).addTo(map);

//       map.flyTo(userLocation, map.getZoom(), {
//         animate: true,
//         duration: 1.5  // Adjust duration as needed for smoother movement
//       });

//       marker.bindPopup("<b>Your Location</b>").openPopup();

//       return () => {
//         map.removeLayer(marker);
//       };
//     }
//   }, [userLocation, map]);

//   return null;
// }

// export default function MapPage() {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [highlightedMarkers, setHighlightedMarkers] = useState([]);
//   const [markersData, setMarkersData] = useState([]);
//   const [searchPerformed, setSearchPerformed] = useState(false);
//   const [regions, setRegions] = useState([]);
//   const [regionInfo, setRegionInfo] = useState(null);
//   const [mapCenter, setMapCenter] = useState([25.3176, 82.9739]);
//   const [selectedMarker, setSelectedMarker] = useState(null);
//   const [hasMore, setHasMore] = useState(true);
//   const [page, setPage] = useState(1);
//   const [userLocation, setUserLocation] = useState(null);

//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   useEffect(() => {
//     const getRegions = async () => {
//       try {
//         const data = await TotalRegionAPI();
//         if (data && data.data && data.data.regions) {
//           console.log('Fetched regions:', data.data.regions);
//           setRegions(data.data.regions);
//         } else {
//           console.error('Failed to fetch regions');
//         }
//       } catch (error) {
//         console.error('Error fetching regions:', error);
//       }
//     };
//     getRegions();
//   }, []);

  

//   const fetchData = async (regionId, pageNumber = 1) => {
//     try {
//       const data = await fetchMarkersByRegion(regionId, pageNumber);
//       console.log('API Response:', data);

//       if (data?.data?.markers) {
//         const sortedMarkers = data.data.markers.sort((a, b) => a.markerId - b.markerId);
//         if (pageNumber === 1) {
//           setMarkersData(sortedMarkers);
//         } else {
//           setMarkersData((prevMarkers) => [...prevMarkers, ...sortedMarkers]);
//         }
//         setSearchPerformed(true);
//         setHasMore(data.data.hasMore);
//         const selectedRegion = regions.find((region) => region.regionId.toString() === regionId);
//         if (selectedRegion) {
//           setRegionInfo(selectedRegion);
//           if (regionId === '1') {
//             setMapCenter([25.3176, 82.9739]);
//           } else if (regionId === '2') {
//             setMapCenter(jaipurCoordinates);
//           }
//         } else {
//           console.warn('Region not found:', regionId);
//           setRegionInfo(null);
//           setMapCenter(null);
//         }
//       } else {
//         console.error('API returned an unexpected response:', data);
//       }
//     } catch (error) {
//       console.error('Error fetching markers:', error);
//     }
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleSearchSubmit = (e) => {
//     e.preventDefault();
//     if (searchQuery) {
//       const region = regions.find((region) => region.regionId.toString() === searchQuery);
//       console.log('Selected region info:', region);
//       setRegionInfo(region);
//       fetchData(searchQuery);
//     }
//   };

//   const handleRefresh = () => {
//     window.location.reload();
//   };

//   const handleMarkerClick = async (markerId) => {
//     try {
//       const data = await fetchMarkersById(markerId);
//       if (data && data.data) {
//         setSelectedMarker(data.data);
//       }
//     } catch (error) {
//       console.error('Error fetching marker details:', error);
//     }
//   };

//   const handleCoordinateClick = (markerId) => {
//     setHighlightedMarkers([markerId]);
//   };

//   const loadMoreMarkers = () => {
//     setPage((prevPage) => {
//       const newPage = prevPage + 1;
//       fetchData(searchQuery, newPage);
//       return newPage;
//     });
//   };

//   const isMobile = window.innerWidth < 900;
//   const sortedMarkers = isMobile ? [...markersData].reverse() : markersData;

//   return (
//     <div className='map-page'>
//       <h3>Markers</h3>
//       <div className='search-box'>
//         <form onSubmit={handleSearchSubmit}>
//           <input
//             type='text'
//             placeholder='Enter region ID to search'
//             value={searchQuery}
//             onChange={handleSearchChange}
//           />
//           <button type='button' onClick={handleRefresh}>
//             <FaSync />
//           </button>
//         </form>
//       </div>

//       {regionInfo && (
//         <div className='about-region'>
//           <p>
//             <span>Region ID:</span> {regionInfo.regionId}
//           </p>
//           <p>
//             <span>Region Name:</span> {regionInfo.regionName}
//           </p>
//           <p>
//             <span>Total Markers:</span> {regionInfo.totalMarkers}
//           </p>
//           <p>
//             <span>Missing Markers: </span>
//             {regionInfo.missingMarkers}
//           </p>
//           <p>
//             <span>Last Trip:</span> {regionInfo.lastTrip}
//           </p>
//         </div>
//       )}

//       {searchPerformed && mapCenter && (
//         <div className='content-container'>
//           <div className='map-container'>
//             <MapContainer center={mapCenter} zoom={15} style={{ height: '60vh', width: '100%' }}>
//               <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='&copy; OpenStreetMap contributors' />
//               {sortedMarkers.map((marker) => (
//                 <Marker
//                   key={marker.markerId}
//                   position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
//                   icon={highlightedMarkers.includes(marker.markerId) ? highlightedIcon : defaultIcon}
//                   eventHandlers={{                  click: () => handleMarkerClick(marker.markerId),
//                   }}
//                 >
//                   <Popup>
//                     <div>Pin {marker.markerId}</div>
//                   </Popup>
//                 </Marker>
//               ))}
              
//             </MapContainer>
//           </div>
//           <div className='marker-list-container'>
//             <div className='marker-list-header'>
//               <h4>Markers</h4>
//               <h4>Coordinates</h4>
//             </div>
//             <InfiniteScroll
//               dataLength={sortedMarkers.length}
//               next={loadMoreMarkers}
//               hasMore={hasMore}
//               loader={<h4>Loading...</h4>}
//               scrollableTarget='marker-list-container'
//             >
//               <div className='marker-list' id='marker-list-container'>
//                 {sortedMarkers.map((marker) => (
//                   <div
//                     key={marker.markerId}
//                     className='marker-row'
//                     onClick={() => handleCoordinateClick(marker.markerId)}
//                     style={{ color: highlightedMarkers.includes(marker.markerId) ? 'red' : 'black' }}
//                   >
//                     <div>Pin {marker.markerId}</div>
//                     <div>{`${marker.latitude}, ${marker.longitude}`}</div>
//                   </div>
//                 ))}
//               </div>
//             </InfiniteScroll>
//           </div>
//         </div>
//         )}
  
//         {selectedMarker && (
//           <PinImagesModal
//             marker={selectedMarker}
//             handleClose={() => setSelectedMarker(null)}
//           />
//         )}
//       </div>
//     );
//   }
  

