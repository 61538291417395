import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaSync } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { GetTripByIDAPI, fetchMarkersByRegion } from '../../Services/APIManager';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; 
import L from 'leaflet';
import '../../Styles/mappage.css';
import { useLocation } from 'react-router-dom';
import grayMarkerIconUrl from '../../Assets/graypin-removebg-preview (1).png';
import redMarkerIconUrl from '../../Assets/redpin-removebg-preview.png';
import { Helmet } from 'react-helmet';
const TripMap = () => {
  const { tripId } = useParams();
  const [searchQuery, setSearchQuery] = useState(tripId || '');
  const [tripData, setTripData] = useState(null);
  const [error, setError] = useState('');
  const [markerData, setMarkerData] = useState({ readMarkers: [], unreadMarkers: [] });
  const [mapCenter, setMapCenter] = useState(null);
  const [highlightedMarkers, setHighlightedMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [listSelectedMarker, setListSelectedMarker] = useState(null); 
  const markerRefs = useRef({});
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (tripId) {
      fetchTripData(tripId);
    }
  }, [tripId]);

  const fetchTripData = async (tripId) => {
    try {
      const data = await GetTripByIDAPI(tripId);
      if (data) {
        setTripData(data);
        setError('');
        const regionId = data.data.regionId;
        const markers = await fetchMarkersByRegion(regionId);
        if (markers) {
          const readMarkers = markers.data.markers.filter(marker => data.data.readMarkersArr.includes(marker.markerId));
          const unreadMarkers = markers.data.markers.filter(marker => data.data.unreadMarkersArr.includes(marker.markerId));
          
          setMarkerData({ readMarkers, unreadMarkers });
          if (!mapCenter && unreadMarkers.length > 0) {
            const firstMarker = unreadMarkers[0];
            setMapCenter([parseFloat(firstMarker.latitude), parseFloat(firstMarker.longitude)]);
          }
        }
      } else {
        setError('Failed to fetch trip details. Please check the trip ID and try again.');
      }
    } catch (error) {
      setError('Failed to fetch trip details. Please check the trip ID and try again.');
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      fetchTripData(searchQuery);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const grayMarkerIcon = L.icon({
    iconUrl: grayMarkerIconUrl,
    iconSize: [40, 40],
    iconAnchor: [15, 50],
    popupAnchor: [1, -34],
  });

  const redMarkerIcon = L.icon({
    iconUrl: redMarkerIconUrl,
    iconSize: [50, 50],
    iconAnchor: [15, 50],
    popupAnchor: [1, -34],
  });

  const handleCoordinateClick = (marker) => {
    setListSelectedMarker(marker);
    if (markerRefs.current[marker.markerId]) {
      markerRefs.current[marker.markerId].openPopup();
    }
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const closeImagePopup = () => {
    setSelectedMarker(null);
  };

  return (
    <div className='map-page'>
       <Helmet>
        <title>Trip Map</title>
        <meta name="description" content="View and manage trip details and markers on the map." />
        <meta name="keywords" content="trip map, markers, GAIL, RFID" />
      </Helmet>
      <h3>Trip Details</h3>
    
      <form onSubmit={handleSearchSubmit}>
        <div className='search-container'>
          <input
            type='text'
            placeholder='Enter Trip ID to search'
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button type='submit'>
            <FaSearch />
          </button>
          <button type='button' onClick={handleRefresh}>
            <FaSync />
          </button>
        </div>
      </form>

      {error && <p className='error'>{error}</p>}

      {tripData && (
        <div className='trip-info'>
          <p>
            <span>Region Name:</span> {tripData.data.regionName}
          </p>
          <p>
            <span>User ID:</span> {tripData.data.userId}
          </p>
          <p>
            <span>Read Markers:</span> {tripData.data.readMarkersArr && tripData.data.readMarkersArr.length > 0 ? `${tripData.data.readMarkersArr.length}` : '0'}
          </p>
          <p>
            <span>Unread Markers:</span> {tripData.data.unreadMarkersArr && tripData.data.unreadMarkersArr.length > 0 ? `${tripData.data.unreadMarkersArr.length}` : '0'}
          </p>
        </div>
      )}

      <div className='map-and-list-container'>
        <div className='map-container'>
          {mapCenter && (
            <MapContainer center={mapCenter} zoom={15} style={{ height: '100%', width: '100%' }}>
              <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

              {markerData.unreadMarkers.length > 0 && (
                markerData.unreadMarkers.map((marker) => (
                  <Marker
                    key={marker.markerId}
                    position={[marker.latitude, marker.longitude]}
                    icon={redMarkerIcon}
                    eventHandlers={{
                      click: () => handleMarkerClick(marker),
                    }}
                    ref={ref => { markerRefs.current[marker.markerId] = ref; }} 
                  >
                    <Popup>
                      <div>
                        <h4>Marker ID: {marker.markerId}</h4>
                        <p>Coordinates: {marker.latitude}, {marker.longitude}</p>
                      </div>
                    </Popup>
                  </Marker>
                ))
              )}
            </MapContainer>
          )}
        </div>

        <div className='marker-list' id='marker-list-container'>
          <div className='marker-list-header'>
            <div className='marker-list-column'>Marker ID</div>
            <div className='marker-list-column'>Coordinates</div>
          </div>
          {markerData.unreadMarkers.concat(markerData.readMarkers).map((marker) => (
            <div
              key={marker.markerId}
              className='marker-list-row'
              onClick={() => handleCoordinateClick(marker)}
              style={{ backgroundColor: markerData.unreadMarkers.includes(marker) ? 'rgba(255, 0, 0, 0.2)' : 'transparent' }}
            >
              <div className='marker-list-column'>{marker.markerId}</div>
              <div className='marker-list-column'>{marker.latitude}, {marker.longitude}</div>
            </div>
          ))}
        </div>
      </div>

      {selectedMarker && (
        <div className='image-popup' onClick={closeImagePopup}>
          <div className='image-popup-content'>
            <button className='close-button' onClick={closeImagePopup}>X</button>
            <div className='marker-id'>Marker ID: {selectedMarker.markerId}</div>
            <img src={selectedMarker.photos[0].photoUrl} alt={`Marker ${selectedMarker.markerId}`} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TripMap;

// import React, { useState, useEffect } from 'react';
// import { FaSearch, FaSync } from 'react-icons/fa';
// import { useParams } from 'react-router-dom';
// import { GetTripByIDAPI, fetchMarkersByRegion } from '../../Services/APIManager';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css'; 
// import L from 'leaflet';
// import '../../Styles/mappage.css';
// import { useLocation } from 'react-router-dom';
// import grayMarkerIconUrl from '../../Assets/graypin-removebg-preview (1).png';
// import redMarkerIconUrl from '../../Assets/redpin-removebg-preview.png';

// const TripMap = () => {
//   const { tripId } = useParams();
//   const [searchQuery, setSearchQuery] = useState(tripId || '');
//   const [tripData, setTripData] = useState(null);
//   const [error, setError] = useState('');
//   const [markerData, setMarkerData] = useState({ readMarkers: [], unreadMarkers: [] });
//   const [mapCenter, setMapCenter] = useState(null);
//   const [highlightedMarkers, setHighlightedMarkers] = useState([]);
//   const [selectedMarker, setSelectedMarker] = useState(null);
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   useEffect(() => {
//     if (tripId) {
//       fetchTripData(tripId);
//     }
//   }, [tripId]);

//   const fetchTripData = async (tripId) => {
//     try {
//       const data = await GetTripByIDAPI(tripId);
//       if (data) {
//         setTripData(data);
//         setError('');
//         const regionId = data.data.regionId;
//         const markers = await fetchMarkersByRegion(regionId);
//         if (markers) {
//           // Filter markers based on read and unread IDs from tripData
//           const readMarkers = markers.data.markers.filter(marker => data.data.readMarkersArr.includes(marker.markerId));
//           const unreadMarkers = markers.data.markers.filter(marker => data.data.unreadMarkersArr.includes(marker.markerId));
          
//           setMarkerData({ readMarkers, unreadMarkers });
//           if (!mapCenter && unreadMarkers.length > 0) {
//             const firstMarker = unreadMarkers[0];
//             setMapCenter([parseFloat(firstMarker.latitude), parseFloat(firstMarker.longitude)]);
//           }
//         }
//       } else {
//         setError('Failed to fetch trip details. Please check the trip ID and try again.');
//       }
//     } catch (error) {
//       setError('Failed to fetch trip details. Please check the trip ID and try again.');
//     }
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleSearchSubmit = (e) => {
//     e.preventDefault();
//     if (searchQuery) {
//       fetchTripData(searchQuery);
//     }
//   };

//   const handleRefresh = () => {
//     window.location.reload();
//   };

//   const grayMarkerIcon = L.icon({
//     iconUrl: grayMarkerIconUrl,
//     iconSize: [40, 40],
//     iconAnchor: [15, 50],
//     popupAnchor: [1, -34],
//   });

//   const redMarkerIcon = L.icon({
//     iconUrl: redMarkerIconUrl,
//     iconSize: [50, 50],
//     iconAnchor: [15, 50],
//     popupAnchor: [1, -34],
//   });

//   const handleCoordinateClick = (markerId) => {
//     setHighlightedMarkers([...highlightedMarkers, markerId]);
//   };

//   const handleMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//   };

//   const closeImagePopup = () => {
//     setSelectedMarker(null);
//   };

//   return (
//     <div className='map-page'>
//       <h3>Trip Details</h3>
    
//       <form onSubmit={handleSearchSubmit}>
//         <div className='search-container'>
//           <input
//             type='text'
//             placeholder='Enter Trip ID to search'
//             value={searchQuery}
//             onChange={handleSearchChange}
//           />
//           <button type='submit'>
//             <FaSearch />
//           </button>
//           <button type='button' onClick={handleRefresh}>
//             <FaSync />
//           </button>
//         </div>
//       </form>

//       {error && <p className='error'>{error}</p>}

//       {tripData && (
//         <div className='trip-info'>
//           <p>
//             <span>Region Name:</span> {tripData.data.regionName}
//           </p>
//           <p>
//             <span>User ID:</span> {tripData.data.userId}
//           </p>
//           <p>
//             <span>Read Markers:</span> {tripData.data.readMarkersArr && tripData.data.readMarkersArr.length > 0 ? tripData.data.readMarkersArr.join(', ') : 'Null'}
//           </p>
//           <p>
//             <span>Unread Markers:</span> {tripData.data.unreadMarkersArr.join(', ')}
//           </p>
//         </div>
//       )}

//       <div className='map-and-list-container'>
//         <div className='map-container'>
//           {mapCenter && (
//             <MapContainer center={mapCenter} zoom={15} style={{ height: '100%', width: '100%' }}>
//               <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

//               {markerData.unreadMarkers.length > 0 && (
//                 markerData.unreadMarkers.map((marker) => (
//                   <Marker
//                     key={marker.markerId}
//                     position={[marker.latitude, marker.longitude]}
//                     icon={redMarkerIcon}
//                     eventHandlers={{
//                       click: () => handleMarkerClick(marker),
//                     }}
//                   >
//                     <Popup>
//                       <div>
//                         <h4>Marker ID: {marker.markerId}</h4>
//                         <p>Coordinates: {marker.latitude}, {marker.longitude}</p>
//                       </div>
//                     </Popup>
//                   </Marker>
//                 ))
//               )}
//             </MapContainer>
//           )}
//         </div>

//         <div className='marker-list' id='marker-list-container'>
//           <div className='marker-list-header'>
//             <div className='marker-list-column'>Marker ID</div>
//             <div className='marker-list-column'>Coordinates</div>
//           </div>
//           {markerData.readMarkers.concat(markerData.unreadMarkers).map((marker) => (
//             <div
//               key={marker.markerId}
//               className='marker-list-row'
//               onClick={() => handleCoordinateClick(marker.markerId)}
//               style={{ backgroundColor: markerData.unreadMarkers.includes(marker) ? 'rgba(255, 0, 0, 0.2)' : 'transparent' }}
//             >
//               <div className='marker-list-column'>{marker.markerId}</div>
//               <div className='marker-list-column'>{marker.latitude}, {marker.longitude}</div>
//             </div>
//           ))}
//         </div>
//       </div>

//       {selectedMarker && (
//         <div className='image-popup'  onClick={closeImagePopup}>
//           <div className='image-popup-content'>
//             <button className='close-button' onClick={closeImagePopup}>X</button>
//             <div className='marker-id'>Marker ID: {selectedMarker.markerId}</div>
//             <img src={selectedMarker.photos[0].photoUrl} alt={`Marker ${selectedMarker.markerId}`} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TripMap;
