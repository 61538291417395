import React, { useState, useEffect } from 'react';
import '../../Styles/users.css'; // Import user.css for styling
import { UsersAPI } from '../../Services/APIManager';
import { Helmet } from 'react-helmet';

const User = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await UsersAPI();
      if (data && data.data) {
        const fetchedUsers = data.data.map(user => ({
          userId: user.userId.toString(),
          username: user.username,
          role: user.role,
          region: user.regions.join(', '), 
          fullName: user.fullName,
          createdAt: new Date(user.userCreatedAt).toLocaleDateString(),
          updatedAt: new Date(user.userUpdatedAt).toLocaleDateString()
        }));
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      } else {
        console.error('Failed to fetch users');
      }
    };

    fetchUsers();
  }, []); //card popup 

  const handleUserSelect = (userId) => {
    const selected = users.find(user => user.userId === userId);
    setSelectedUser(selected);
  }; //function to open the card

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterUsers(searchTerm);
  };

  const filterUsers = (term) => {
    if (!term.trim()) {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(user =>
        user.userId.toLowerCase().includes(term.toLowerCase()) ||
        user.username.toLowerCase().includes(term.toLowerCase()) ||
        user.role.toLowerCase().includes(term.toLowerCase()) ||
        user.region.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  const handleClose = () => {
    setSelectedUser(null);
  };

  return (
    <div className="user-container">
      <Helmet>
        <title>User Management - GAIL Marker Surveillance System</title>
        <meta name="description" content="User management dashboard for GAIL Marker Surveillance System including search and detailed user information." />
        <meta name="keywords" content="GAIL, User Management, Marker Surveillance, User Details, Search Users" />
      </Helmet>
      <h3 style={{marginBottom:'20px'}}>Riders Info</h3>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button><i className="fa fa-search"></i></button> 
      </div>
      {selectedUser && (
        <div className="selected-user" onClick={handleClose}>
          <div className="selected-card">
            <h3>User Details</h3>
            <button className="close-button" onClick={handleClose}><i className="fa-solid fa-xmark"></i></button>
            <p><span>User ID: </span>{selectedUser.userId}</p>
            <p><span>Username:</span> {selectedUser.username}</p>
            <p><span>Role:</span> {selectedUser.role}</p>
            <p><span>Region: </span>{selectedUser.region}</p>
            <p><span>Full Name:</span> {selectedUser.fullName}</p>
            <p><span>Created At:</span> {selectedUser.createdAt}</p>
            <p><span>Updated At:</span> {selectedUser.updatedAt}</p>
          </div>
        </div>
      )}
      <div className="usercard-container">
        {filteredUsers.map(user => (
          <div className="usercard" key={user.userId} onClick={() => handleUserSelect(user.userId)}>
            <h4>{user.username}</h4>
            <p><span>User ID: </span>{user.userId}</p>
            <p><span>Role: </span>{user.role}</p>
            <p><span>Region:</span> {user.region}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default User;


// import React, { useState, useEffect } from 'react';

// import '../../Styles/users.css'; // Import user.css for styling
// import { UsersAPI } from '../../Services/APIManager';

// const User = () => {
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     // Placeholder for fetching users from API
//     const fetchedUsers = [
//       { userId: '1', username: 'John Doe', role: 'Admin', region: 'North America' },
//       { userId: '2', username: 'Jane Smith', role: 'User', region: 'Europe' },
//       { userId: '3', username: 'Mike Johnson', role: 'User', region: 'Asia' },
//       { userId: '4', username: 'Emily Brown', role: 'Admin', region: 'Australia' },
//     ];
//     setUsers(fetchedUsers);
//     setFilteredUsers(fetchedUsers);
//   }, []);
//   useEffect(() => {
//     const getRegions = async () => {
//       const data = await UsersAPI();
//       if (data && data.data) {
//         setUser(data.data);
//       } else {
//         console.error('Failed to fetch regions');
//       }
//     };
//     getRegions();
//   }, []);
//   // Function to handle user selection (e.g., clicking on a card)
//   const handleUserSelect = (userId) => {
//     const selected = users.find(user => user.userId === userId);
//     setSelectedUser(selected);
//   };

//   // Function to handle search input change
//   const handleSearchChange = (event) => {
//     const searchTerm = event.target.value;
//     setSearchTerm(searchTerm);
//     filterUsers(searchTerm);
//   };

//   // Function to filter users based on search term
//   const filterUsers = (term) => {
//     if (!term.trim()) {
//       setFilteredUsers(users); // Reset to display all users if search term is empty
//     } else {
//       const filtered = users.filter(user =>
//         user.userId.toLowerCase().includes(term.toLowerCase()) ||
//         user.username.toLowerCase().includes(term.toLowerCase()) ||
//         user.role.toLowerCase().includes(term.toLowerCase()) ||
//         user.region.toLowerCase().includes(term.toLowerCase())
//       );
//       setFilteredUsers(filtered);
//     }
//   };

//   // Function to handle closing the selected user details
//   const handleClose = () => {
//     setSelectedUser(null);
//   };

//   // JSX for user search and cards display
//   return (
//     <div className="user-container">
//       <div className="search-container">
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchTerm}
//           onChange={handleSearchChange}
//         />
//         <button><i className="fa fa-search"></i></button> {/* Search icon */}
//       </div>
//       {selectedUser && (
//         <div className="selected-user" onClick={handleClose}>
//           <div className="selected-card">
//             <h3>User Details</h3>
//             <button className="close-button" onClick={handleClose}><i className="fa-solid fa-xmark"></i></button>
//             <p><span>User ID: </span>{selectedUser.userId}</p>
//             <p><span>Username:</span> {selectedUser.username}</p>
//             <p><span>Role:</span> {selectedUser.role}</p>
//             <p><span>Region: </span>{selectedUser.region}</p>
//             <p><span>Full Name:</span> {selectedUser.username}</p>
//             <p><span>Created At:</span> 19/05/2024</p>
//             <p><span>Updated At:</span> 10/06/2024</p>
//           </div>
//         </div>
//       )}
//       <div className="usercard-container">
//         {filteredUsers.map(user => (
//           <div className="usercard" key={user.userId} onClick={() => handleUserSelect(user.userId)}>
//             <h4>{user.username}</h4>
//             <p><span>User ID: </span>{user.userId}</p>
//             <p><span>Role: </span>{user.role}</p>
//             <p><span>Region:</span> {user.region}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default User;


