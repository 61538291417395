import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../../Styles/trippage.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const tripData = [
    {
        tripId: 'T1',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg',
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T2',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
          'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
          'https://www.gailonline.com/images/about/Vision.jpg',
          'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T3',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T4',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T5',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T6',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T7',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T5',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T6',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
      {
        tripId: 'T7',
        riderName: 'John Doe',
        regionName: 'New York',
        startTime: '2024-06-01 10:00',
        endTime: '2024-06-01 12:00',
        markersRead: 10,
        markersUnread: 5,
        totalMarkers: 15,
        photos: [
            'https://bsmedia.business-standard.com/_media/bs/img/article/2023-03/27/full/1679906879-6815.jpg',
            'https://www.gailonline.com/images/about/Vision.jpg',
            'https://www.gailonline.com/images/home/Big-img-3.jpg'
        ]
      },
 
 
];
export default function PhotoCollagePage() {
    const { tripId } = useParams();
    const [trip, setTrip] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  
    useEffect(() => {
      const selectedTrip = tripData.find(trip => trip.tripId === tripId);
      setTrip(selectedTrip);
    }, [tripId]);

    useEffect(() => {
        const elements = document.querySelectorAll('.trip-card');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target); 
                }
            });
        }, { threshold: 0.1 });

        elements.forEach(element => observer.observe(element));

        return () => observer.disconnect();
    }, []);
  
    const openModal = (index) => {
      setCurrentPhotoIndex(index);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const previousPhoto = () => {
      setCurrentPhotoIndex((prevIndex) => (prevIndex === 0 ? trip.photos.length - 1 : prevIndex - 1));
    };
  
    const nextPhoto = () => {
      setCurrentPhotoIndex((prevIndex) => (prevIndex === trip.photos.length - 1 ? 0 : prevIndex + 1));
    };

    const handleOverlayClick = (e) => {
      if (e.target.classList.contains('modal')) {
        closeModal();
      }
    };
  
    if (!trip) {
      return <div>Loading...</div>;
    }
  
    return (
      <div className='photo-collage-page'>
        <h3>Photos for Trip {trip.tripId}</h3>
        <div className='photo-collage' style={{margin:10}}>
          {trip.photos.map((photo, index) => (
            <img
              src={photo}
              alt={`Trip Photo ${index}`}
              key={index}
              className='collage-photo'
              onClick={() => openModal(index)}
            />
          ))}
        </div>
  
        {isModalOpen && (
          <div className='modal' onClick={handleOverlayClick}>
            <div className='modal-content'>
              <span className='close-button' onClick={closeModal}><i className="fa-solid fa-xmark"></i> <span className='close'>close</span></span>
              <div className='carousel'>
                <button className='carousel-button left' onClick={previousPhoto}><i className="fa-solid fa-chevron-left"></i></button>
                <img src={trip.photos[currentPhotoIndex]} alt={`Trip Photo ${currentPhotoIndex}`} className='carousel-image' />
                <button className='carousel-button right' onClick={nextPhoto}><i className="fa-solid fa-chevron-right"></i></button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}