import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '../../Styles/trippage.css';
import { TripsAPI, fetchMissingMarkers } from '../../Services/APIManager';
import GailMarker from '../../Assets/gailmarker.jpg';
import { Helmet } from 'react-helmet';
const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

const convertImageToBase64 = (imagePath) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        fetch(imagePath)
            .then(response => response.blob())
            .then(blob => reader.readAsDataURL(blob))
            .catch(reject);
    });
};

export default function TripPage() {
    const [trips, setTrips] = useState([]);
    const [filteredTrips, setFilteredTrips] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const [base64Image, setBase64Image] = useState('');
    const [missingMarkers, setMissingMarkers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchTrips(1, 58); 
        convertImageToBase64(GailMarker).then(setBase64Image).catch(console.error);
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
          const data = await fetchMissingMarkers();
          setMissingMarkers(data);
        };
        
        fetchData();
      }, []);
      
    
    const fetchTrips = useCallback(async (page, limit) => {
        setLoading(true);
        const data = await TripsAPI(page, limit);
        if (data && data.data) {
            const fetchedTrips = data.data.trips.map(trip => ({
                tripId: trip.tripId.toString(),
                riderName: trip.username,
                regionName: trip.regionName,
                startTime: new Date(trip.startTime).toLocaleString('en-US', { 
                    timeZone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }),
                  endTime: trip.endTime ? new Date(trip.endTime).toLocaleString('en-US', {
                    timeZone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }) : 'Not yet ended',
                markersRead: trip.readMarkers,
                markersUnread: trip.unreadMarkers,
                totalMarkers: trip.totalMarkers,
                photos: []
            }));

            setTrips(prevTrips => {
                const newTrips = fetchedTrips.filter(trip =>
                    !prevTrips.some(prevTrip => prevTrip.tripId === trip.tripId)
                );
                return [...prevTrips, ...newTrips];
            });
            setFilteredTrips(prevTrips => {
                const newTrips = fetchedTrips.filter(trip =>
                    !prevTrips.some(prevTrip => prevTrip.tripId === trip.tripId)
                );
                return [...prevTrips, ...newTrips];
            });

            if (fetchedTrips.length < limit) {
                setHasMore(false);
            }
        } else {
            console.error('Failed to fetch trips');
        }
        setLoading(false);
    }, []);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setHasMore(true);
        setPage(1);

        if (!value.trim()) {
            setFilteredTrips(trips);
        }
    };

    const handleSearchClick = async () => {
        if (!searchTerm.trim()) {
            setFilteredTrips(trips);
        } else {
            setLoading(true);
            let tripFound = false;
            let currentPage = 1;
            const limit = 12;
            let matchingTrip = null;

            while (!tripFound && hasMore) {
                const data = await TripsAPI(currentPage, limit);
                if (data && data.data) {
                    const fetchedTrips = data.data.trips.map(trip => ({
                        tripId: trip.tripId.toString(),
                        riderName: trip.username,
                        regionName: trip.regionName,
                        startTime: new Date(trip.startTime).toLocaleString('en-US', { 
                            timeZone: 'UTC',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                          }),
                          endTime: trip.endTime ? new Date(trip.endTime).toLocaleString('en-US', {
                            timeZone: 'UTC',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                          }) : 'Not yet ended',
                        markersRead: trip.readMarkers,
                        markersUnread: trip.unreadMarkers,
                        totalMarkers: trip.totalMarkers,
                        photos: []
                    }));

                    const filtered = fetchedTrips.filter(trip =>
                        trip.tripId.toLowerCase() === searchTerm.toLowerCase()
                    );

                    if (filtered.length > 0) {
                        matchingTrip = filtered[0];
                        tripFound = true;
                        setHasMore(false);
                    } else {
                        currentPage += 1;
                        if (fetchedTrips.length < limit) {
                            setHasMore(false);
                        }
                    }
                } else {
                    console.error('Failed to fetch trips');
                    break;
                }
            }
            setFilteredTrips(matchingTrip ? [matchingTrip] : []);
            setLoading(false);
        }
    };

    const handlePhotoClick = (tripId) => {
        navigate(`/trip/${tripId}/photos`);
    };

    const handleMapClick = (tripId) => {
        navigate(`/tripmap/${tripId}`);
    };

    const lastTripElementRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && !loading && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        }, options);

        if (lastTripElementRef.current) {
            observer.current.observe(lastTripElementRef.current);
        }

        return () => {
            if (observer.current && lastTripElementRef.current) {
                observer.current.unobserve(lastTripElementRef.current);
            }
        };
    }, [lastTripElementRef.current, loading, hasMore]);

    useEffect(() => {
        if (page > 1) {
            fetchTrips(page, 12);
        }
    }, [page, fetchTrips]);

    const generateHTML = () => {
        const title = 'GAIL Marker Surveillance System';
        const subtitle = 'Report on marker monitoring trips conducted in the DLW region (Varanasi), during July 2024';
        const introduction = `This pilot project is deployed in the DLW region of Varanasi city, UP. All the pipeline markers, (metal and cement) have been tagged using RFID technology, and their data is stored on the cloud servers along with their geolocation coordinates. Monitoring staff (aka riders) have a handheld RFID-enabled mobile device that they use to monitor the presence of these markers by riding across the entire DLW region on their two-wheelers.
        The following table details the trips that were conducted during the month of July 2024.`;
    
        const tripRows = trips.map(trip => `
            <tr>
                <td>${trip.tripId}</td>
                <td>${trip.riderName}</td>
                <td>${trip.regionName}</td>
                <td>${trip.startTime}</td>
                <td>${trip.endTime}</td>
                <td>${trip.markersRead}</td>
                <td>${trip.markersUnread}</td>
                <td>${trip.totalMarkers}</td>
            </tr>
        `).join('');
    
        const missingMarkersDescription = 'Below is the list of markers that are currently marked as missing. This information is crucial for the maintenance team to identify and locate the missing markers.';
        
        const missingMarkerRows = Array.isArray(missingMarkers) ? missingMarkers.map(marker => `
            <tr>
                <td>${marker.markerId}</td>
                <td>${marker.regionName}</td>
                <td>${marker.latitude}</td>
                <td>${marker.longitude}</td>
            </tr>
        `).join('') : '';
    
        return `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>${title}</title>
                <style>
                    body { font-family: Arial, sans-serif; padding: 20px; text-align: center; padding:10px; }
                    h1 { color: #f2aa84; }
                    h2 { color: #777;font-size: 18px; }
                    img {  margin: 20px 0; object-fit:cover}
                    h4 { margin: 10px 0;font-style: italic;font-size: 16px; }
                    h3 {   color: #0c5b84; text-align: left; margin-top: 20px; }
                    p { text-align: left; line-height: 23px;
                        margin-bottom: 50px; }
                    table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                    th, td { border: 1px solid #f2aa84  ; padding: 8px; text-align: center; }
                    th { background-color: #FDF3EE; color: #0c5b84; }
                </style>
            </head>
            <body>
                <h1>${title}</h1>
                <h2>${subtitle}</h2>
                <img src="${base64Image}" alt="GAIL Marker Surveillance System Image"/>
                <h4>Prepared by BQTech Pvt. Ltd. (IOT-RFID Division of Binary Systems)</h4>
                <h3>Introduction</h3>
                <p>${introduction}</p>
                <table>
                    <thead>
                        <tr>
                            <th>Trip ID</th>
                            <th>Rider Name</th>
                            <th>Region Name</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Markers Read</th>
                            <th>Markers Unread</th>
                            <th>Total Markers</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tripRows}
                    </tbody>
                </table>
                <h3>Missing Markers</h3>
                <p>${missingMarkersDescription}</p>
                <table>
                    <thead>
                        <tr>
                            <th>Marker ID</th>
                            <th>Region Name</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${missingMarkerRows}
                    </tbody>
                </table>
            </body>
            </html>
        `;
    };
    
    
    const handleDownloadClick = () => {
        const htmlContent = generateHTML();
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'trip_report.html';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <div className='trip-page'>
             <Helmet>
                <title>Trip Management - GAIL Marker Surveillance System</title>
                <meta name="description" content="Manage and monitor trips for the GAIL Marker Surveillance System." />
                <meta name="keywords" content="GAIL, marker surveillance, trips, monitoring, RFID" />
            </Helmet>
            <h3>Trips</h3>
            <div className='search-container'>
                <input
                    type="text"
                    placeholder="Search by Trip ID..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <button onClick={handleSearchClick}><i className="fa fa-search"></i></button>
                <button onClick={handleDownloadClick}><i className="fa fa-download"></i></button>
            </div>
            <div className='trip-container'>
                {filteredTrips.map((trip, index) => (
                    <TripCard key={index} trip={trip} handlePhotoClick={handlePhotoClick} handleMapClick={handleMapClick} />
                ))}
                <div ref={lastTripElementRef}></div>
            </div>
            {loading && hasMore && <p>Loading...</p>}
        </div>
    );
}

function TripCard({ trip, handlePhotoClick, handleMapClick }) {
    const [ref, inView] = useInView({ triggerOnce: true });

    return (
        <motion.div
            ref={ref}
            className='trip-card'
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={transition}
            onClick={() => handleMapClick(trip.tripId)}
        >
            <h4>Trip ID: {trip.tripId}</h4>
            <p><span>Rider Name:</span> {trip.riderName}</p>
            <p><span>Region Name:</span> {trip.regionName}</p>
            <p><span>Start Time: </span>{trip.startTime}</p>
            <p><span>End Time:</span> {trip.endTime}</p>
            <p><span>Markers Read:</span> {trip.markersRead}</p>
            <p><span>Markers Unread:</span> {trip.markersUnread}</p>
            <p><span>Total Markers: </span>{trip.totalMarkers}</p>
            <p>
                <span>Photos Clicked: </span>
                <span className='photo-count' onClick={(e) => { e.stopPropagation(); handlePhotoClick(trip.tripId); }}>{trip.photos.length}</span>
            </p>
        </motion.div>
    );
}



// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
// import '../../Styles/trippage.css';
// import { TripsAPI } from '../../Services/APIManager';
// import GailMarker from '../../Assets/gailmarker.jpg';
// import { Helmet } from 'react-helmet';
// const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

// const convertImageToBase64 = (imagePath) => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => resolve(reader.result);
//         reader.onerror = reject;
//         fetch(imagePath)
//             .then(response => response.blob())
//             .then(blob => reader.readAsDataURL(blob))
//             .catch(reject);
//     });
// };

// export default function TripPage() {
//     const [trips, setTrips] = useState([]);
//     const [filteredTrips, setFilteredTrips] = useState([]);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [page, setPage] = useState(1);
//     const [loading, setLoading] = useState(false);
//     const [hasMore, setHasMore] = useState(true);
//     const observer = useRef();
//     const [base64Image, setBase64Image] = useState('');

//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchTrips(1, 58); // Fetching all trips
//         convertImageToBase64(GailMarker).then(setBase64Image).catch(console.error);
//     }, []);

//     const fetchTrips = useCallback(async (page, limit) => {
//         setLoading(true);
//         const data = await TripsAPI(page, limit);
//         if (data && data.data) {
//             const fetchedTrips = data.data.trips.map(trip => ({
//                 tripId: trip.tripId.toString(),
//                 riderName: trip.username,
//                 regionName: trip.regionName,
//                 startTime: new Date(trip.startTime).toLocaleString('en-US', { 
//                     timeZone: 'UTC',
//                     hour: 'numeric',
//                     minute: 'numeric',
//                     second: 'numeric',
//                     hour12: true,
//                     year: 'numeric',
//                     month: 'numeric',
//                     day: 'numeric'
//                   }),
//                   endTime: trip.endTime ? new Date(trip.endTime).toLocaleString('en-US', {
//                     timeZone: 'UTC',
//                     hour: 'numeric',
//                     minute: 'numeric',
//                     second: 'numeric',
//                     hour12: true,
//                     year: 'numeric',
//                     month: 'numeric',
//                     day: 'numeric'
//                   }) : 'Not yet ended',
//                 markersRead: trip.readMarkers,
//                 markersUnread: trip.unreadMarkers,
//                 totalMarkers: trip.totalMarkers,
//                 photos: []
//             }));

//             setTrips(prevTrips => {
//                 const newTrips = fetchedTrips.filter(trip =>
//                     !prevTrips.some(prevTrip => prevTrip.tripId === trip.tripId)
//                 );
//                 return [...prevTrips, ...newTrips];
//             });
//             setFilteredTrips(prevTrips => {
//                 const newTrips = fetchedTrips.filter(trip =>
//                     !prevTrips.some(prevTrip => prevTrip.tripId === trip.tripId)
//                 );
//                 return [...prevTrips, ...newTrips];
//             });

//             if (fetchedTrips.length < limit) {
//                 setHasMore(false);
//             }
//         } else {
//             console.error('Failed to fetch trips');
//         }
//         setLoading(false);
//     }, []);

//     const handleSearchChange = (event) => {
//         const value = event.target.value;
//         setSearchTerm(value);
//         setHasMore(true);
//         setPage(1);

//         if (!value.trim()) {
//             setFilteredTrips(trips);
//         }
//     };

//     const handleSearchClick = async () => {
//         if (!searchTerm.trim()) {
//             setFilteredTrips(trips);
//         } else {
//             setLoading(true);
//             let tripFound = false;
//             let currentPage = 1;
//             const limit = 12;
//             let matchingTrip = null;

//             while (!tripFound && hasMore) {
//                 const data = await TripsAPI(currentPage, limit);
//                 if (data && data.data) {
//                     const fetchedTrips = data.data.trips.map(trip => ({
//                         tripId: trip.tripId.toString(),
//                         riderName: trip.username,
//                         regionName: trip.regionName,
//                         startTime: new Date(trip.startTime).toLocaleString('en-US', { 
//                             timeZone: 'UTC',
//                             hour: 'numeric',
//                             minute: 'numeric',
//                             second: 'numeric',
//                             hour12: true,
//                             year: 'numeric',
//                             month: 'numeric',
//                             day: 'numeric'
//                           }),
//                           endTime: trip.endTime ? new Date(trip.endTime).toLocaleString('en-US', {
//                             timeZone: 'UTC',
//                             hour: 'numeric',
//                             minute: 'numeric',
//                             second: 'numeric',
//                             hour12: true,
//                             year: 'numeric',
//                             month: 'numeric',
//                             day: 'numeric'
//                           }) : 'Not yet ended',
//                         markersRead: trip.readMarkers,
//                         markersUnread: trip.unreadMarkers,
//                         totalMarkers: trip.totalMarkers,
//                         photos: []
//                     }));

//                     const filtered = fetchedTrips.filter(trip =>
//                         trip.tripId.toLowerCase() === searchTerm.toLowerCase()
//                     );

//                     if (filtered.length > 0) {
//                         matchingTrip = filtered[0];
//                         tripFound = true;
//                         setHasMore(false);
//                     } else {
//                         currentPage += 1;
//                         if (fetchedTrips.length < limit) {
//                             setHasMore(false);
//                         }
//                     }
//                 } else {
//                     console.error('Failed to fetch trips');
//                     break;
//                 }
//             }
//             setFilteredTrips(matchingTrip ? [matchingTrip] : []);
//             setLoading(false);
//         }
//     };

//     const handlePhotoClick = (tripId) => {
//         navigate(`/trip/${tripId}/photos`);
//     };

//     const handleMapClick = (tripId) => {
//         navigate(`/tripmap/${tripId}`);
//     };

//     const lastTripElementRef = useRef(null);

//     useEffect(() => {
//         const options = {
//             root: null,
//             rootMargin: '0px',
//             threshold: 1.0
//         };

//         observer.current = new IntersectionObserver((entries) => {
//             if (entries[0].isIntersecting && !loading && hasMore) {
//                 setPage(prevPage => prevPage + 1);
//             }
//         }, options);

//         if (lastTripElementRef.current) {
//             observer.current.observe(lastTripElementRef.current);
//         }

//         return () => {
//             if (observer.current && lastTripElementRef.current) {
//                 observer.current.unobserve(lastTripElementRef.current);
//             }
//         };
//     }, [lastTripElementRef.current, loading, hasMore]);

//     useEffect(() => {
//         if (page > 1) {
//             fetchTrips(page, 12);
//         }
//     }, [page, fetchTrips]);

//     const generateHTML = () => {
//         const title = 'GAIL Marker Surveillance System';
//         const subtitle = 'Report on marker monitoring trips conducted in the DLW region (Varanasi), during July 2024';
//         const introduction = `This pilot project is deployed in the DLW region of Varanasi city, UP. All the pipeline markers, (metal and cement) have been tagged using RFID technology, and their data is stored on the cloud servers along with their geolocation coordinates. Monitoring staff (aka riders) have a handheld RFID-enabled mobile device that they use to monitor the presence of these markers by riding across the entire DLW region on their two-wheelers.
//         The following table details the trips that were conducted during the month of July 2024.`;

//         const tripRows = trips.map(trip => `
//             <tr>
//                 <td>${trip.tripId}</td>
//                 <td>${trip.riderName}</td>
//                 <td>${trip.regionName}</td>
//                 <td>${trip.startTime}</td>
//                 <td>${trip.endTime}</td>
//                 <td>${trip.markersRead}</td>
//                 <td>${trip.markersUnread}</td>
//                 <td>${trip.totalMarkers}</td>
//             </tr>
//         `).join('');

//         return `
//             <!DOCTYPE html>
//             <html lang="en">
//             <head>
//                 <meta charset="UTF-8">
//                 <meta name="viewport" content="width=device-width, initial-scale=1.0">
//                 <title>${title}</title>
//                 <style>
//                     body { font-family: Arial, sans-serif; padding: 20px; text-align: center; padding:10px; }
//                     h1 { color: #f2aa84; }
//                     h2 { color: #777;font-size: 18px; }
//                     img {  margin: 20px 0; object-fit:cover}
//                     h4 { margin: 10px 0;font-style: italic;font-size: 16px; }
//                     h3 {   color: #0c5b84; text-align: left; margin-top: 20px; }
//                     p { text-align: left; line-height: 23px;
//                         margin-bottom: 50px; }
//                     table { width: 100%; border-collapse: collapse; margin-top: 20px; }
//                     th, td { border: 1px solid #f2aa84  ; padding: 8px; text-align: center; }
//                     th { background-color: #FDF3EE; color: #0c5b84; }
//                 </style>
//             </head>
//             <body>
//                 <h1>${title}</h1>
//                 <h2>${subtitle}</h2>
//                 <img src="${base64Image}" alt="GAIL Marker Surveillance System Image"/>
//                 <h4>Prepared by BQTech Pvt. Ltd. (IOT-RFID Division of Binary Systems)</h4>
//                 <h3>Introduction</h3>
//                 <p>${introduction}</p>
//                 <table>
//                     <thead>
//                         <tr>
//                             <th>Trip ID</th>
//                             <th>Rider Name</th>
//                             <th>Region Name</th>
//                             <th>Start Time</th>
//                             <th>End Time</th>
//                             <th>Markers Read</th>
//                             <th>Markers Unread</th>
//                             <th>Total Markers</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         ${tripRows}
//                     </tbody>
//                 </table>
//             </body>
//             </html>
//         `;
//     };

//     const handleDownloadClick = () => {
//         const htmlContent = generateHTML();
//         const blob = new Blob([htmlContent], { type: 'text/html' });
//         const url = URL.createObjectURL(blob);
//         const a = document.createElement('a');
//         a.href = url;
//         a.download = 'trip_report.html';
//         document.body.appendChild(a);
//         a.click();
//         document.body.removeChild(a);
//         URL.revokeObjectURL(url);
//     };

//     return (
//         <div className='trip-page'>
//              <Helmet>
//                 <title>Trip Management - GAIL Marker Surveillance System</title>
//                 <meta name="description" content="Manage and monitor trips for the GAIL Marker Surveillance System." />
//                 <meta name="keywords" content="GAIL, marker surveillance, trips, monitoring, RFID" />
//             </Helmet>
//             <h3>Trips</h3>
//             <div className='search-container'>
//                 <input
//                     type="text"
//                     placeholder="Search by Trip ID..."
//                     value={searchTerm}
//                     onChange={handleSearchChange}
//                 />
//                 <button onClick={handleSearchClick}><i className="fa fa-search"></i></button>
//                 <button onClick={handleDownloadClick}><i className="fa fa-download"></i></button>
//             </div>
//             <div className='trip-container'>
//                 {filteredTrips.map((trip, index) => (
//                     <TripCard key={index} trip={trip} handlePhotoClick={handlePhotoClick} handleMapClick={handleMapClick} />
//                 ))}
//                 <div ref={lastTripElementRef}></div>
//             </div>
//             {loading && hasMore && <p>Loading...</p>}
//         </div>
//     );
// }

// function TripCard({ trip, handlePhotoClick, handleMapClick }) {
//     const [ref, inView] = useInView({ triggerOnce: true });

//     return (
//         <motion.div
//             ref={ref}
//             className='trip-card'
//             initial={{ opacity: 0, y: 50 }}
//             animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
//             transition={transition}
//             onClick={() => handleMapClick(trip.tripId)}
//         >
//             <h4>Trip ID: {trip.tripId}</h4>
//             <p><span>Rider Name:</span> {trip.riderName}</p>
//             <p><span>Region Name:</span> {trip.regionName}</p>
//             <p><span>Start Time: </span>{trip.startTime}</p>
//             <p><span>End Time:</span> {trip.endTime}</p>
//             <p><span>Markers Read:</span> {trip.markersRead}</p>
//             <p><span>Markers Unread:</span> {trip.markersUnread}</p>
//             <p><span>Total Markers: </span>{trip.totalMarkers}</p>
//             <p>
//                 <span>Photos Clicked: </span>
//                 <span className='photo-count' onClick={(e) => { e.stopPropagation(); handlePhotoClick(trip.tripId); }}>{trip.photos.length}</span>
//             </p>
//         </motion.div>
//     );
// }


