import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/LoginPage/Login';
import PrivateRoute from './PrivateRoute';
import Cards from './components/Cards/Cards';
import Markers from './components/Markers/Marker';
import TripPage from './components/TripPage/TripPage';
import PhotoCollagePage from './components/TripPage/TripImages';
import MapPage from './components/MarkersMap/Map';
import PinImagesPage from './components/MarkersMap/PinImagesPage';
import Layout from './components/Sidebar/Sidebar';
import Home from './components/Home/Home';
import Region from './components/RegionCard/region';
import User from './components/Users/Users';
import TripMap from './components/TripPage/TripMappage';
export default function App() {
  return (
    <div>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route element={<Layout/>}>
            <Route path='/' element={<Home/>} />
            <Route path='/dashboard' element={<Cards />} />
            <Route path='/trip-page' element={<TripPage />} />
            <Route path='/markers' element={<Markers />} />
            <Route path='/regions' element={<Region/>} />
            <Route path='/getusers' element={<User/>} />
            <Route path='/trip/:tripId/photos' element={<PhotoCollagePage />} />
            <Route path='/markers-map' element={<MapPage />} />
            <Route path='/tripmap/:tripId' element={<TripMap />} />
            {/* <Route path="/tripmap" element={<TripMap/>} /> */}
            <Route path='/pin/:id' element={<PinImagesPage />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
     
    </div>
  );
}
