import React from 'react';
import '../../Styles/markerdetails.css';

const MarkerDetails = ({ tid, markerId, photo, latitude, longitude }) => {
  const photoURL = typeof photo === 'object' ? URL.createObjectURL(photo) : photo;

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className='marker-details'>
        <h3>Marker Details</h3>
        <p><span>TID:</span> {tid}</p>
        <p><span>Marker ID:</span> {markerId}</p>
        {latitude && (
          <p><span>Location:</span> Latitude: {latitude}</p>
        )}
        {longitude && (
          <p>Longitude: {longitude}</p>
        )}
        {photo && (
          <div>
            <span>Photo:</span>
            <img src={photoURL} alt="Captured" style={{ width: '200px', height: '200px' }} />
          </div>
        )}
      </div>
      <button className='detail-refresh' onClick={handleRefresh}>Create a new Marker</button>
    </>
  );
};

export default MarkerDetails;

// import React from 'react';
// import '../../Styles/markerdetails.css';

// const MarkerDetails = ({ tripId, photo, latitude, longitude }) => {
//   const photoURL = typeof photo === 'object' ? URL.createObjectURL(photo) : photo;

//   const handleRefresh = () => {
//     window.location.reload();
//   };

//   return (
//     <>
//       <div className='marker-details'>
//         <h3>Marker Details</h3>
//         <p><span>TID:</span> {tripId}</p>
//         {latitude && (
//           <p><span>Location:</span> Latitude: {latitude}</p>
//         )}
//         {longitude && (
//           <p>Longitude: {longitude}</p>
//         )}
//         {photo && (
//           <div>
//             <span>Photo:</span>
//             <img src={photoURL} alt="Captured" style={{ width: '200px', height: '200px' }} />
//           </div>
//         )}
//       </div>
//       <button className='detail-refresh' onClick={handleRefresh}>Create a new Marker</button>
//     </>
//   );
// };

// export default MarkerDetails;
