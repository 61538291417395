import config from "../config";
const DEFAULT_URL = config.BASE_URL;
const GAILPLOT_URL = config.GAIL_URL;

export const TripDetailsAPI = async (tripId) => {
  try {
    const TripList = await fetch(DEFAULT_URL + `getTripDetails/?tripId=${tripId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!TripList.ok) {
      throw new Error("Failed to get Trip Details");
    }

    const resultData = await TripList.json();
    return resultData;
  } catch (e) {
    console.log("Error in getting Trip Details", e);
    return null;
  }
};

export const LoginAPI = async (username, password) => {
  try {
    const requestBody = {
      username: username,
      password: password
    };

    const loginResult = await fetch(DEFAULT_URL + "login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody)
    });
    if (!loginResult.ok) {
      
      throw new Error("Failed to login");
    }

    const resultData = await loginResult.json();
    console.log("Login Successful", resultData);
    return resultData;
  } catch (e) {
    console.error("Error in Login", e);
    return null;
  }
};
export const PhotoUploadAPI = async (photo, token) => {
  try {
    const formData = new FormData();
    formData.append('photo', photo);

    const response = await fetch(GAILPLOT_URL + "upload-photo", {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData,
      redirect: "follow"
    });
    const resultData = await response.json();
     console.log("Photo uploaded successfully", resultData);
    return {
      response: response, 
      resultData:resultData 
    };

  } catch (e) {
    console.log("Error in photo upload", e);
    return 401;
  }
};
export const CreateMarker = async (tripId, latitude, longitude, photoUrl, token) => {
  try {
    const requestBody = {
      tid: tripId,
      latitude: latitude,
      longitude: longitude,
      photoUrl: photoUrl
    };

    const response = await fetch(GAILPLOT_URL + "markers", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    const resultData = await response.json();
    console.log("Marker creation response:", resultData);

    return {
      response: response,
      resultData: resultData
    };
  } catch (e) {
    console.error("Error in creating Marker:", e);
    return null;
  }
};

// export const CreateMarker = async (latitude, longitude, photoUrl, token) => {
//   try {
//     const requestBody = {
//       latitude: latitude,
//       longitude: longitude,
//       photoUrl: photoUrl
//     };
//     const response = await fetch(GAILPLOT_URL + "markers", {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//       body: JSON.stringify(requestBody)
//     });

//     const resultData = await response.json();
//     console.log("Marker created successfully", resultData);
//     return {
//       response: response, 
//       resultData:resultData 
//     };
//   } catch (e) {
//     console.log("Error in creating Marker", e);
//     return null;
//   }
// };
export const TotalRegionAPI = async () => {
  try {
    const token = localStorage.getItem('webtoken'); 
    if (!token) {
      throw new Error("No web token found in local storage");
    }

    console.log("Using token:", token);

    const response = await fetch(DEFAULT_URL + "regions/search", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, 
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to get the count of regions: ${response.status} - ${response.statusText} - ${errorText}`);
    }

    const resultData = await response.json();
    console.log("TotalRegionAPI response:", resultData);
    return resultData;
  } catch (e) {
    console.error("Error in getting region count", e);
    return null;
  }
};

export const StatusAPI = async () => {
  try {
    const token = localStorage.getItem('webtoken');
    const response = await fetch(DEFAULT_URL + 'users/dashboard', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch dashboard status');
    }

    const resultData = await response.json();
    return resultData;
  } catch (error) {
    console.error('Error in StatusAPI:', error);
    return { error: error.message }; 
  }
};
export const GetUsersAPI = async () => {
  try {
    const token = localStorage.getItem('webtoken');
    const response = await fetch(DEFAULT_URL + 'users/dashboard', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch dashboard status');
    }

    const resultData = await response.json();
    return resultData;
  } catch (error) {
    console.error('Error in StatusAPI:', error);
    return { error: error.message }; 
  }
};


export const TripsAPI = async (page, limit) => {
  try {
    const token = localStorage.getItem('webtoken');
    const TripList = await fetch(`${DEFAULT_URL}trips/search?p=${page}&l=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!TripList.ok) {
      throw new Error("Failed to get Trip Details");
    }

    const resultData = await TripList.json();
    return resultData;
  } catch (e) {
    console.log("Error in getting Trip Details", e);
    return null;
  }
};


export const UsersAPI = async () => {
  try {
    const token = localStorage.getItem('webtoken');
    const UserList = await fetch(DEFAULT_URL + "users/search?role=monitoring_staff", { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!UserList.ok) {
      throw new Error("Failed to get User Details");
    }

    const resultData = await UserList.json();
    return resultData;
  } catch (e) {
    console.log("Error in getting User Details", e);
    return null;
  }
};
export const fetchMarkersByRegion = async (regionId) => {
  try {
    const token = localStorage.getItem('webtoken');
    const response = await fetch(`${DEFAULT_URL}markers/search?r=${regionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch markers for region ${regionId}: ${response.statusText}`);
    }

    const responseData = await response.json();
    console.log('API Response:', responseData);

    return responseData;
  } catch (error) {
    console.error(`Error fetching markers for region ${regionId}:`, error);
    return null;
  }
};
export const fetchMarkersById = async (id) => {
  try {
    const token = localStorage.getItem('webtoken');
    const response = await fetch(`${DEFAULT_URL}markers/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch marker with id ${id}: ${response.statusText}`);
    }

    const responseData = await response.json();
    console.log('API Response:', responseData);

    return responseData; 
  } catch (error) {
    console.error(`Error fetching marker with id ${id}:`, error);
    throw error; 
  }
};
export const GetTripByIDAPI = async (tripId) => {
  try {
    const token = localStorage.getItem('webtoken');
    const response = await fetch(`${DEFAULT_URL}trips/${tripId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch marker');
    }

    const responseData = await response.json();
    console.log('get Trips by Id:', responseData);

    return responseData; 
  } catch (error) {
    console.error('Error fetching marker', error);
    throw error; 
  }
};


export const GetMarkersByIDAPI = async (markerId) => {
  try {
    const token = localStorage.getItem('webtoken');
    const response = await fetch(DEFAULT_URL+"markers/${markerId}", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const responseText = await response.text();
    console.log('Response text:', responseText); 

    if (!response.ok) {
      throw new Error(`Failed to get Marker Details: ${responseText}`);
    }

    try {
      const resultData = JSON.parse(responseText);
      return resultData;
    } catch (jsonError) {
      throw new Error(`Invalid JSON response: ${responseText}`);
    }
  } catch (e) {
    console.log("Error in getting Marker Details", e.message); 
    return null;
  }
};

export const tripfetchMarkersById = async (markerId) => {
  try {
    const token = localStorage.getItem('webtoken');
    const response = await fetch(`${DEFAULT_URL}markers/${markerId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch marker with id ${markerId}: ${response.statusText}`);
    }

    const responseData = await response.json();
    console.log('API Response:', responseData);

    return responseData.data; 
  } catch (error) {
    console.error(`Error fetching marker with id ${markerId}:`, error);
    throw error;
  }
};

export const fetchMissingMarkers = async () => {
  try {
    const token = localStorage.getItem('webtoken');
    const response = await fetch(`${DEFAULT_URL}markers/search?st=missing`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch missing markers: ${response.statusText}`);
    }

    const responseData = await response.json();
    console.log('API Response:', responseData);
    if (responseData.data && Array.isArray(responseData.data.marker)) {
      return responseData.data.marker;
    } else {
      throw new Error('Invalid response format: expected an array of markers');
    }
  } catch (error) {
    console.error('Error fetching missing markers:', error);
    return [];
  }
};


