import React, { useState, useEffect, useRef } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import GailLogo from '../../Assets/gailLogo.png';
import Profile from '../../Assets/profile.jpg';
import '../../Styles/header.css';
import '../../Styles/sidebar.css';
import ConfirmationPopup from '../SignOut/SignOut';
import Footer from '../Footer/Footer';

export default function Layout() {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const confirmationRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = 'unset'; 
    }
  };

  const closeSidebar = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset'; 
  };

  const handleSignOut = () => {
    setShowConfirmation(true);
  };

  const handleConfirmSignOut = () => {
    localStorage.removeItem('webToken');
    window.location.href = '/login';
  };

  const handleCancelSignOut = () => {
    setShowConfirmation(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }

    if (isOpen && !event.target.closest('.sidebar') && !event.target.closest('.toggle-button')) {
      setIsOpen(false);
      document.body.style.overflow = 'unset'; 
    }

    if (showConfirmation && confirmationRef.current && !confirmationRef.current.contains(event.target)) {
      setShowConfirmation(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, showDropdown, showConfirmation]);

  return (
    <div className="layout-container">
      <div className='navbar'>
        <div className='logo-toggle'>
          <div className='toggle-button' onClick={toggleSidebar}>
            <div className='line line1'></div>
            <div className='line line2'></div>
            <div className='line line3'></div>
          </div>
          <NavLink to="/" style={{ cursor: 'pointer' }}>
            <img src={GailLogo} alt='gail-logo' />
          </NavLink>
        </div>
        <div className='profile' onClick={toggleDropdown} ref={dropdownRef}>
          <div className='user-name'>Admin</div>
          <img src={Profile} alt='profile' />
          <div className={`dropdown ${showDropdown ? 'show' : ''}`}>
            <div className='dropdown-item' onClick={handleSignOut}>Sign out</div>
          </div>
        </div>
      </div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className='menu'>
          <ul>
            <NavLink to="/dashboard" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Dashboard</li></NavLink>
            <NavLink to="/getusers" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Users</li></NavLink>
            <NavLink to="/trip-page" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Trips</li></NavLink>
            <NavLink to="/regions" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Regions</li></NavLink>
            <div className='line-separate'></div>
            <NavLink to="/markers-map" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Markers</li></NavLink>
            <NavLink to="/markers" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Create Markers</li></NavLink>
            <div className='line-separate'></div>
            <div onClick={handleSignOut}><li onClick={closeSidebar}>Sign out</li></div>
          </ul>
        </div>
      </div>

      {showConfirmation && (
        <div ref={confirmationRef}>
          <ConfirmationPopup
            message="Are you sure you want to end your session?"
            onConfirm={handleConfirmSignOut}
            onCancel={handleCancelSignOut}
          />
        </div>
      )}
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

// import React, { useState, useEffect, useRef } from 'react';
// import { Outlet, NavLink } from 'react-router-dom'; // Import NavLink for active link styling
// import GailLogo from '../../Assets/gailLogo.png';
// import Profile from '../../Assets/profile.jpg';
// import '../../Styles/header.css';
// import '../../Styles/sidebar.css';
// import ConfirmationPopup from '../SignOut/SignOut';
// import Footer from '../Footer/Footer';

// export default function Layout() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [showConfirmation, setShowConfirmation] = useState(false);
//   const [showDropdown, setShowDropdown] = useState(false);
//   const dropdownRef = useRef(null);
//   const confirmationRef = useRef(null);

//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//     if (!isOpen) {
//       document.body.style.overflow = 'hidden'; // Disable scroll when sidebar opens
//     } else {
//       document.body.style.overflow = 'unset'; // Enable scroll when sidebar closes
//     }
//   };

//   const closeSidebar = () => {
//     setIsOpen(false);
//     document.body.style.overflow = 'unset'; // Always enable scroll when sidebar closes
//   };

//   const handleSignOut = () => {
//     setShowConfirmation(true);
//   };

//   const handleConfirmSignOut = () => {
//     localStorage.removeItem('webToken');
//     window.location.href = '/login';
//   };

//   const handleCancelSignOut = () => {
//     setShowConfirmation(false);
//   };

//   const toggleDropdown = () => {
//     setShowDropdown(!showDropdown);
//   };

//   const handleOutsideClick = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setShowDropdown(false);
//     }

//     if (isOpen && !event.target.closest('.sidebar')) {
//       setIsOpen(false);
//       document.body.style.overflow = 'unset'; // Enable scroll when sidebar closes due to outside click
//     }

//     if (showConfirmation && confirmationRef.current && !confirmationRef.current.contains(event.target)) {
//       setShowConfirmation(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('mousedown', handleOutsideClick);
//     return () => {
//       document.removeEventListener('mousedown', handleOutsideClick);
//     };
//   }, [isOpen, showDropdown, showConfirmation]);

//   return (
//     <div>
//       <div className='navbar'>
//         <div className='logo-toggle'>
//           <div className='toggle-button' onClick={toggleSidebar}>
//             <div className='line line1'></div>
//             <div className='line line2'></div>
//             <div className='line line3'></div>
//           </div>
//           <NavLink to="/" style={{ cursor: 'pointer' }}>
//             <img src={GailLogo} alt='gail-logo' />
//           </NavLink>
//         </div>
//         <div className='profile' onClick={toggleDropdown} ref={dropdownRef}>
//           <div className='user-name'>Admin</div>
//           <img src={Profile} alt='profile' />
//           <div className={`dropdown ${showDropdown ? 'show' : ''}`}>
//             <div className='dropdown-item' onClick={handleSignOut}>Sign out</div>
//           </div>
//         </div>
//       </div>
//       <div className={`sidebar ${isOpen ? 'open' : ''}`}>
//         <div className='menu'>
//           <ul>
//             <NavLink to="/dashboard" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Dashboard</li></NavLink>
//             <NavLink to="/getusers" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Users</li></NavLink>
//             <NavLink to="/trip-page" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Trips</li></NavLink>
//             <NavLink to="/regions" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Regions</li></NavLink>
//             <div className='line-separate'></div>
//             <NavLink to="/markers-map" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Markers</li></NavLink>
//             <NavLink to="/markers" onClick={closeSidebar} className={({ isActive }) => isActive ? 'active' : ''}><li>Create Markers</li></NavLink>
//             <div className='line-separate'></div>
//             <div onClick={handleSignOut}><li onClick={closeSidebar}>Sign out</li></div>
//           </ul>
//         </div>
//       </div>

//       {showConfirmation && (
//         <div ref={confirmationRef}>
//           <ConfirmationPopup
//             message="Are you sure you want to end your session?"
//             onConfirm={handleConfirmSignOut}
//             onCancel={handleCancelSignOut}
//           />
//         </div>
//       )}
//       <Outlet />
//     </div>
//   );
// }

